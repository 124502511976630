import { Collapse, Tooltip, Flex, Progress } from "antd";
import { type FC, useEffect, useState } from "react";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import populateRatesDosage from "../../../pages/new-trail/hooks/PopulateRatesDosage";
import infoIcon from "../../../assets/images/info.svg";

import {
  type RatesDosageDataInterface,
  type PlotsTableComponentProps,
  type Replicants,
} from "../../../pages/new-trail/types";
import PlotTable from "./PlotTable";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

const RepicantsLabel = ({
  data = [],
}: {
  data: Replicants[];
}): React.ReactElement => <>{`+ ${data.length} replicants`}</>;

const PlotsComponent: FC<PlotsTableComponentProps> = ({ from }) => {
  const {
    ratesDosage,
    newTrialState,
    setRatesDosage,
    activeKeys,
    trialProtocolData,
    trialInfo,
    bioApplicationIndex,
    setBioApplicationIndex,
  } = useNewTrialState();
  const { t } = useTranslation();
  const [displayPercent, setDisplayPercent] = useState(0);
  const measureUnitFromTrialInfo =
    trialInfo?.trial_plots?.[0]?.properties?.seeds?.measure_unit ?? null;
  const measureUnitFromProtocol =
    trialProtocolData?.product_details?.seeds?.measure_unit;

  const biologicalData = trialProtocolData?.product_details?.biologicals;

  const unit =
    ratesDosage.unit_of_rate ??
    measureUnitFromTrialInfo ??
    measureUnitFromProtocol;

  useEffect(() => {
    const ratesDosageValue = populateRatesDosage(
      newTrialState?.trial_plots,
      activeKeys,
      ratesDosage?.rates_and_dosages || [],
      unit,
      ratesDosage?.standard_seed_rate,
      ratesDosage?.standard_product,
      ratesDosage?.standard_fertilisers,
      ratesDosage?.standard_biological_application_settings
    );
    setRatesDosage(ratesDosageValue);
  }, [newTrialState?.trial_plots, activeKeys]);

  const trialPlots = newTrialState.trial_plots;
  const ratesAndDosesData = ratesDosage?.rates_and_dosages;
  const vaildateField = (data: RatesDosageDataInterface): any => {
    const errors = { variety: "", range: "" };
    if (!data || ((!data.variety || data.variety === "") && data.rate !== 0)) {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }

    // product from dropdown
    const selectedProduct =
      trialProtocolData?.product_details?.seeds?.products?.find(
        (product: any) => product?.variety === data?.variety
      );

    if (!selectedProduct) return errors;

    let range = trialProtocolData?.product_details?.seeds?.global_density_range
      ? trialProtocolData?.product_details?.seeds?.density_range
      : selectedProduct.density_range || {};

    if (
      trialProtocolData?.product_details?.seeds?.measure_unit !==
      ratesDosage?.unit_of_rate
    ) {
      if (ratesDosage?.unit_of_rate === "seeds/m2") {
        range = {
          min: (range?.min ?? 1) / 10000,
          max: (range?.max ?? 1) / 10000,
        };
      } else if (ratesDosage?.unit_of_rate === "seeds/ha") {
        range = {
          min: (range?.min ?? 1) * 10000,
          max: (range?.max ?? 1) * 10000,
        };
      }
    }

    if (!data.variety || data.variety === "") {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }
    if (!data.rate || data.rate === 0) {
      errors.range = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.rate")
      );
    } else if (
      !isNaN(data.rate) &&
      data.rate >= range?.min &&
      data.rate <= range?.max
    ) {
      errors.range = "";
    } else {
      errors.range = `Range for selected product is ${
        String(range?.min) ?? "n/a"
      } to ${String(range?.max) ?? "n/a"}`;
    }
    return errors;
  };

  const flattenedRates = ratesDosage?.rates_and_dosages
    .flatMap((item) => item)
    .map((item) => {
      return {
        ...item,
        errors: vaildateField(item) || { variety: "", range: "" },
      };
    });

  const handleApplication = (i: number): void => {
    setBioApplicationIndex(i);
  };

  const allBioTreatmentsValid =
    ratesAndDosesData?.length &&
    ratesAndDosesData?.every((plotArray) =>
      plotArray?.every(
        (plot) =>
          Array.isArray(plot?.bioTreatement) &&
          plot?.bioTreatement?.length > bioApplicationIndex &&
          plot?.bioTreatement?.every(
            (treatment) => typeof treatment?.treated === "boolean"
          )
      )
    );

  useEffect(() => {
    if (allBioTreatmentsValid) {
      setDisplayPercent(
        bioApplicationIndex + 1 ===
          (biologicalData?.applications?.length
            ? biologicalData?.applications?.length
            : 1)
          ? 100
          : ((bioApplicationIndex + 1) /
              (biologicalData?.applications?.length
                ? biologicalData?.applications?.length
                : 1)) *
              100
      );
    }
  }, [allBioTreatmentsValid, bioApplicationIndex, biologicalData]);

  const totalProductPerApplication = ratesAndDosesData?.reduce(
    (total, plotArray) =>
      total +
      plotArray.reduce(
        (plotTotal, plot) =>
          plotTotal +
          (plot?.bioTreatement?.[bioApplicationIndex]?.treated
            ? biologicalData?.products?.[0]?.rate ?? 0
            : 0),
        0
      ),
    0
  );

  return (
    <>
      <div
        className={
          from === "biologicals"
            ? "standard-seedrate-main"
            : "plots-collapse-main"
        }
      >
        {from === "biologicals" && (
          <>
            <div className="seedrate-header">
              <p className="seedrate-heading">
                {formatTranslation(
                  t("trials.plotDesign.trialPlots.plotTreatment")
                )}
              </p>
              <Tooltip
                placement="right"
                title={formatTranslation(
                  t("trials.plotDesign.StandardFertilizerRate.tooltip")
                )}
              >
                <img
                  src={infoIcon}
                  alt="info-icon"
                  className="seedrate-info-icon"
                />
              </Tooltip>
            </div>
            <Flex vertical gap={4}>
              <div className="product-info-label">
                {formatTranslation(t("createProtocol.summary.title5"))}
              </div>
              <Flex>
                {biologicalData?.applications?.map((data, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => {
                      handleApplication(index);
                    }}
                    className={
                      "application-name" +
                      (bioApplicationIndex === index ? " isActiveApp" : "")
                    }
                  >
                    {index + 1}
                  </button>
                ))}
              </Flex>
              <div className="application-info">
                {biologicalData?.applications[bioApplicationIndex]?.description}
              </div>
            </Flex>
          </>
        )}
        {trialPlots?.map((plotsItem: any, index: number) => (
          <div className="collpase-one" key={plotsItem?.plot?.id}>
            <Collapse
              defaultActiveKey={plotsItem?.plot?.id}
              className="ant-collapse-item"
              expandIconPosition="start"
            >
              <Collapse.Panel
                key={plotsItem?.plot?.id}
                header={
                  <div className="plots-col">
                    <h4>Plot {index + 1}</h4>
                    {plotsItem?.replicants?.length ? (
                      <RepicantsLabel data={plotsItem?.replicants || []} />
                    ) : (
                      <></>
                    )}
                  </div>
                }
              >
                <PlotTable
                  ratesAndDoses={ratesAndDosesData[index]}
                  productDetails={trialProtocolData?.product_details?.[from]}
                  flattenedRates={flattenedRates}
                  vaildateField={vaildateField}
                  from={from}
                  plotKey={index}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
        ))}

        {from === "biologicals" && (
          <Flex
            className="application-step"
            align="center"
            justify="space-between"
            gap={8}
          >
            <Flex gap={8} justify="space-between" align="center">
              <Progress
                type="circle"
                percent={parseFloat(displayPercent?.toFixed(1))}
                size={60}
              />
              <p className="application-step-count">
                Application {bioApplicationIndex + 1} of{" "}
                {biologicalData?.applications?.length
                  ? biologicalData?.applications?.length
                  : 1}
              </p>
            </Flex>
            {biologicalData?.applications?.length !== 0 &&
              biologicalData?.applications?.length !==
                bioApplicationIndex + 1 && (
                <button
                  className={` application-step-btn ${
                    !allBioTreatmentsValid
                      ? "application-step-btn-disabled"
                      : ""
                  }
                  `}
                  type="button"
                  onClick={() => {
                    setBioApplicationIndex((prev) => prev + 1);
                  }}
                  disabled={!allBioTreatmentsValid}
                >
                  {formatTranslation(t("createProtocol.summary.nextApp"))}
                </button>
              )}
          </Flex>
        )}
      </div>
      {from === "biologicals" && (
        <div
          className="product-info-label"
          style={{ padding: "0 16px", display: "none" }}
        >
          {formatTranslation(
            t("trials.plotDesign.trialPlots.plotTotalApplication")
          )}
          : {totalProductPerApplication} L
        </div>
      )}
    </>
  );
};

export default PlotsComponent;
