import React from "react";
import ReactDOM from "react-dom/client";
import "syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less";
import "./index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { type CWEnv, initCWElements } from "@cw-elements/config";
import AppProviders from "./context";
import { BrowserRouter } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { options } from "./amplitudeOptions";
import "./i18n";
import "./launchDarkly.ts";
import Smartlook from "smartlook-client";

const amplitudeOptions = options ?? {};
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_KEY ?? null;
const SMARTLOOK_API_KEY = process.env.REACT_APP_SMARTLOOK_API_KEY ?? null;

if (AMPLITUDE_API_KEY) {
  amplitude.init(AMPLITUDE_API_KEY, amplitudeOptions);
}

if (SMARTLOOK_API_KEY) {
  Smartlook.init(SMARTLOOK_API_KEY);
}

const token = (): string => localStorage.getItem("tokens") ?? "";
export const getAccessToken = (): string => {
  try {
    return JSON.parse(token()).access_token;
  } catch (e) {
    return "";
  }
};

const initClients = (): any => {
  initCWElements({
    environment: process.env.REACT_APP_CROPWISE_PLATFORM_ENV as CWEnv,
    token: getAccessToken(),
    hostAppId: process.env.REACT_APP_TRIALING_APP_ID as string,
  });
};
try {
  initClients();
} catch (e) {
  console.error("Error", e);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProviders>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProviders>
  </React.StrictMode>
);

reportWebVitals();
