import { useEffect, useState, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  Form,
  Tooltip,
  InputNumber,
  Row,
  Col,
  ConfigProvider,
} from "antd";
import { formatTranslation } from "../../../../../../shared/utils/translationUtils";
import infoIcon from "../../../../../../assets/images/info.svg";
import PlatFormAPIAxiosInstance from "../../../../../../shared/utils/platFormAxios";
import { useCreateProtocolForm } from "../../../..";
import { DropdownEmptyState } from "../../../../../../shared/layout/DropdownEmptyState";

interface OrgValueInterface {
  orgId: string;
  country: string;
  loading?: boolean;
}
const getBiologicalsProductOptions = (products: any[]): any[] => {
  let options: any[] = [];
  if (Array.isArray(products)) {
    options = products?.map((product: any) => {
      return {
        label: (
          <div className="bio-product-option-label">
            <div className="product-name">{product?.name}</div>
            <div className="product-indication">{product?.indication}</div>
          </div>
        ),
        value: product?.id,
        key: product?.id,
        variety: product?.name,
        measure_unit: product?.selling_units,
      };
    });
  }
  return options;
};

const SelectBiologicalProduct = ({
  loading = false,
  country = "",
  orgId = "",
}: OrgValueInterface): ReactElement => {
  const { form, setFormValidationData, formValidationData } =
    useCreateProtocolForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productOptions, setProductOptions] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const handleChange = (key: string, value: string): any => {
    const curProduct = productOptions.find(
      (product: any) => product.value === value
    );
    setSelectedProduct({ ...curProduct, rate: 0 });
  };

  useEffect(() => {
    if (country && orgId) {
      fetchProducts().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [country, orgId]);

  useEffect(() => {
    if (!loading && !isLoading) {
      setInitialData();
    }
  }, [loading, isLoading]);

  useEffect(() => {
    if (selectedProduct) {
      updateBioProductFormData(selectedProduct);
    }
  }, [selectedProduct]);

  function setInitialData(): void {
    const formValues = form.getFieldsValue(true);
    const product = formValues?.biologicals?.products?.[0];
    if (product) {
      setSelectedProduct({
        variety: product?.variety,
        rate: product?.rate,
        value: product?.id,
        measure_unit: product?.measure_unit,
      });
    }
  }

  function updateBioProductFormData(product: any): void {
    const formValues = form.getFieldsValue(true);
    if (product) {
      form.setFieldsValue({
        biologicals: {
          ...formValues?.biologicals,
          products: [
            {
              id: product?.value,
              variety: product?.variety,
              rate: product?.rate,
              measure_unit: product?.measure_unit,
            },
          ],
        },
      });
      setFormValidationData({
        ...formValidationData,
        biologicalProducts: [
          {
            id: product?.value,
            variety: product?.variety,
            rate: product?.rate,
            measure_unit: product?.measure_unit,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        biologicals: { ...formValues?.biologicals, products: [] },
      });
      setFormValidationData({ ...formValidationData, biologicalProducts: [] });
    }
  }

  const fetchProducts = async (): Promise<any> => {
    const [product1, product2, product3, product4] = await Promise.all([
      PlatFormAPIAxiosInstance.get(
        `/catalog/products?full=true&indication=BIOLOGICAL-CONTROL-PRODUCT&country=${country}`
      ),
      PlatFormAPIAxiosInstance.get(
        `/catalog/products?full=true&indication=BIOSTIMULANT&country=${country}`
      ),
      PlatFormAPIAxiosInstance.get(
        `/catalog/orgs/${orgId}/products?full=true&indication=BIOLOGICAL-CONTROL-PRODUCT&country=${country}`
      ),
      PlatFormAPIAxiosInstance.get(
        `/catalog/orgs/${orgId}/products?full=true&indication=BIOSTIMULANT&country=${country}`
      ),
    ]);

    const options = getBiologicalsProductOptions([
      ...product1?.data?.content,
      ...product2?.data?.content,
      ...product3?.data?.content,
      ...product4?.data?.content,
    ]);
    setProductOptions(options);
    setIsLoading(false);
  };

  function handleRateChange(rate: any): void {
    setSelectedProduct({ ...selectedProduct, rate: parseFloat(rate) });
  }

  return (
    <div className="input-container">
      <div className="product-config">
        <h3 className="config-header">
          {formatTranslation(
            t("createProtocol.products.biologicals.product.configuration")
          )}
        </h3>
        <Tooltip
          placement="right"
          title={formatTranslation(
            t(
              "createProtocol.products.biologicals.product.configuration.tooltip"
            )
          )}
        >
          <img src={infoIcon} alt="info-icon" className="info-icon" />
        </Tooltip>
      </div>
      <Row gutter={16}>
        <Col span={16}>
          <Form>
            <ConfigProvider
              renderEmpty={() => <DropdownEmptyState isloading={isLoading} />}
            >
              <Form.Item
                label={formatTranslation(
                  t(
                    "createProtocol.products.biologicals.product.chooseProductLabel"
                  )
                )}
                required
                rules={[
                  {
                    required: true,
                    message: formatTranslation(
                      t("createProtocol.products.biologicals.product.error")
                    ),
                  },
                ]}
              >
                <Select
                  loading={loading || isLoading}
                  placeholder={formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.chooseProductPlaceholder"
                    )
                  )}
                  value={selectedProduct?.value || null}
                  onChange={(product: string): void => {
                    handleChange("product", product);
                  }}
                  options={productOptions}
                  size="large"
                />
              </Form.Item>
            </ConfigProvider>
          </Form>
        </Col>
        <Col span={8}>
          <Form>
            <Form.Item
              label={formatTranslation(
                t("createProtocol.products.biologicals.product.rateLabel")
              )}
              required
              rules={[
                {
                  required: true,
                  message: formatTranslation(
                    t(
                      "createProtocol.products.biologicals.product.rateErrorLabel"
                    )
                  ),
                },
              ]}
            >
              <InputNumber
                // placeholder={formatTranslation(
                //   t(
                //     "createProtocol.products.biologicals.product.ratePlaceholder"
                //   )
                // )}
                type="number"
                value={selectedProduct?.rate || ""}
                disabled={!selectedProduct || loading}
                onChange={(rate: any): void => {
                  handleRateChange(rate);
                }}
                size="large"
                addonAfter={
                  <span style={{ fontSize: "12px" }}>
                    {selectedProduct?.measure_unit === "KILOGRAMS"
                      ? "kg/ha"
                      : "bla/ha"}
                  </span>
                }
                className="product-rate-input"
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SelectBiologicalProduct;
