import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { NewTrialType } from "../types";
import bioApplicationIcon from "./icons/bioApplication.svg";
import seedRateIcon from "./icons/seedRate.svg";
import seedProductIcon from "./icons/seedProduct.svg";
import plotSizeIcon from "./icons/plotSize.svg";
import plotNameIcon from "./icons/plotName.svg";
import fertilizerProductNameIcon from "./icons/fertilizerProductName.svg";
import bioProductNameIcon from "./icons/bioProductName.svg";
import "./SubplotDetailsCard.less";
import { formatTranslation } from "../../../shared/utils/translationUtils";

interface SubplotDetailsCardProps {
  trailData: NewTrialType;
  properties: any;
  ratesAndDosage: any;
  trialProtocolData: any;
}

const DetailSection: React.FC<{
  title: string;
  color: string;
  children: React.ReactNode;
}> = ({ title, color, children }) => (
  <div className={`detail-section ${color}`}>
    <h4>{title}</h4>
    {children}
  </div>
);

const DetailItem: React.FC<{
  icon: string;
  label: string;
  value: string | number;
}> = ({ icon, label, value }) => (
  <div className="detail-item">
    <div className="label">
      <img src={icon} alt={`${label} Icon`} />
      <span>{label}</span>
    </div>
    <span className="value">{value}</span>
  </div>
);

const PlotDetails: React.FC<{
  plot: any;
  subPlotIndex: number;
  plotIndex: number;
  properties: any;
}> = ({ plot, subPlotIndex, properties, plotIndex }) => {
  const { t } = useTranslation();

  return (
    <div className="plot-details">
      <h3>
        {formatTranslation(t("plotDetails.plotGroup"))}{" "}
        {String.fromCharCode(65 + plotIndex)}{" "}
        {properties?.isReplicant
          ? formatTranslation(t("plotDetails.replicant"))
          : ""}
      </h3>
      <DetailItem
        icon={plotNameIcon}
        label={formatTranslation(t("plotDetails.plotName"))}
        value={subPlotIndex + 1}
      />
      <DetailItem
        icon={plotSizeIcon}
        label={formatTranslation(t("plotDetails.plotSize"))}
        value={`${(plot?.plot?.column_size as string) ?? ""}*${
          (plot?.plot?.row_size as string) ?? ""
        }`}
      />
    </div>
  );
};

export const SubplotDetailsCard: React.FC<SubplotDetailsCardProps> = ({
  trailData,
  properties,
  ratesAndDosage,
  trialProtocolData,
}) => {
  const { t } = useTranslation();
  const [trailPlotIndex, setTrailPlotIndex] = useState<number | null>(null);
  const [subPlotIndex, setSubPlotIndex] = useState<number | null>(null);
  const isHovered = useRef<boolean>(false);

  const leaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastEnterEventRef = useRef<{ index: number; subIndex: number } | null>(
    null
  );

  useEffect(() => {
    const handleCustomEvent = (event: any): void => {
      const { index, subIndex, type } = event.detail;

      if (type === "enter") {
        if (leaveTimeoutRef.current) {
          clearTimeout(leaveTimeoutRef.current);
          leaveTimeoutRef.current = null;
        }
        setTrailPlotIndex(index);
        setSubPlotIndex(subIndex);
        lastEnterEventRef.current = { index, subIndex };
      } else if (type === "leave") {
        if (
          lastEnterEventRef.current &&
          lastEnterEventRef.current.index === index &&
          lastEnterEventRef.current.subIndex === subIndex
        ) {
          leaveTimeoutRef.current = setTimeout(() => {
            if (!isHovered.current) {
              setTrailPlotIndex(null);
              setSubPlotIndex(null);
              lastEnterEventRef.current = null;
            }
          }, 2000);
        }
      } else if (type === "leaveAll") {
        if (leaveTimeoutRef.current) {
          clearTimeout(leaveTimeoutRef.current);
          leaveTimeoutRef.current = null;
        }
        setTrailPlotIndex(null);
        setSubPlotIndex(null);
        lastEnterEventRef.current = null;
      }
    };

    window.addEventListener("subplotMouseOver", handleCustomEvent);

    return () => {
      window.removeEventListener("subplotMouseOver", handleCustomEvent);
    };
  }, []);
  const plot = trailData.trial_plots[trailPlotIndex ?? 0];
  const ratesAndDosages =
    ratesAndDosage?.rates_and_dosages ??
    plot?.properties?.seeds?.rates_and_dosages;

  if (
    trailPlotIndex != null &&
    subPlotIndex != null &&
    trailPlotIndex >= 0 &&
    subPlotIndex >= 0
  ) {
    const currentRatesAndDosages =
      ratesAndDosages?.[trailPlotIndex]?.[subPlotIndex];

    return (
      <div
        id="subPlotDetailsCard"
        onMouseOver={() => {
          isHovered.current = true;
        }}
        onMouseLeave={() => {
          isHovered.current = false;

          const myEvent = new CustomEvent("subplotMouseOver", {
            detail: {
              message: `focus removed from plot ${trailPlotIndex} ${subPlotIndex}`,
              type: "leaveAll",
              index: trailPlotIndex,
              subIndex: subPlotIndex,
            },
          });
          window.dispatchEvent(myEvent);
        }}
      >
        <div className="details-container">
          <PlotDetails
            plot={plot}
            plotIndex={trailPlotIndex}
            subPlotIndex={subPlotIndex}
            properties={properties}
          />
          {trialProtocolData?.protocol_type &&
            trialProtocolData?.protocol_type?.includes?.("seed") && (
              <DetailSection
                title={formatTranslation(t("plotDetails.seeds"))}
                color="seeds"
              >
                {currentRatesAndDosages && (
                  <>
                    <DetailItem
                      icon={seedProductIcon}
                      label={formatTranslation(t("plotDetails.product"))}
                      value={currentRatesAndDosages.variety}
                    />
                    <DetailItem
                      icon={seedRateIcon}
                      label={formatTranslation(t("plotDetails.seedRate"))}
                      value={`${
                        (currentRatesAndDosages?.rate as string) ?? ""
                      } ${
                        String(ratesAndDosage?.unit_of_rate) ??
                        plot?.properties?.seeds?.measure_unit
                      }`}
                    />
                  </>
                )}
              </DetailSection>
            )}
          {trialProtocolData?.protocol_type &&
            trialProtocolData?.protocol_type?.includes?.("biological") && (
              <DetailSection
                title={formatTranslation(t("plotDetails.biologicals"))}
                color="biologicals"
              >
                {currentRatesAndDosages?.bioTreatement && (
                  <>
                    <DetailItem
                      icon={bioProductNameIcon}
                      label={formatTranslation(t("plotDetails.product"))}
                      value={` ${
                        (ratesAndDosage
                          ?.standard_biological_application_settings?.[0]
                          ?.product as string) ?? ""
                      }`}
                    />
                    {currentRatesAndDosages.bioTreatement.map(
                      (treatment: { treated: boolean }, index: number) => (
                        <DetailItem
                          key={index}
                          icon={bioApplicationIcon}
                          label={`${formatTranslation(
                            t("plotDetails.application")
                          )} ${index + 1}:`}
                          value={
                            treatment.treated
                              ? `${formatTranslation(
                                  t("plotDetails.treated")
                                )} - ${
                                  (ratesAndDosage
                                    ?.standard_biological_application_settings?.[0]
                                    ?.tank_application_volume as string) ?? ""
                                } ${
                                  (ratesAndDosage
                                    ?.standard_biological_application_settings?.[0]
                                    ?.unit as string) ?? ""
                                }`
                              : formatTranslation(t("plotDetails.untreated"))
                          }
                        />
                      )
                    )}
                  </>
                )}
              </DetailSection>
            )}
          {trialProtocolData?.protocol_type &&
            trialProtocolData?.protocol_type?.includes?.("nutrient") && (
              <DetailSection
                title={formatTranslation(t("plotDetails.fertilisers"))}
                color="fertilisers"
              >
                {currentRatesAndDosages && (
                  <>
                    {ratesAndDosage?.standard_fertilisers?.map(
                      (
                        fertiliser: {
                          fertiliser: string;
                          rate: number;
                          unit_of_rate: string;
                        },
                        index: number
                      ) => (
                        <DetailItem
                          key={index}
                          icon={fertilizerProductNameIcon}
                          label={fertiliser.fertiliser}
                          value={`${(
                            (Number(fertiliser.rate) / 100) *
                            Number(currentRatesAndDosages.fertiliser_rate)
                          ).toFixed(2)} ${
                            String(fertiliser.unit_of_rate) ?? ""
                          }`}
                        />
                      )
                    )}
                  </>
                )}
              </DetailSection>
            )}
        </div>
      </div>
    );
  }

  return null;
};
