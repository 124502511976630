import {
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  ConfigProvider,
} from "antd";
import { useEffect, useState, type ReactElement } from "react";
import { formatCropsData } from "../utils";
import { useCreateProtocolForm } from "../index";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import PlatFormAPIAxiosInstance from "../../../shared/utils/platFormAxios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import type { protocolCountries } from "../shared/interfaces/product-interfaces";
import { DropdownEmptyState } from "../../../shared/layout/DropdownEmptyState";
import infoIcon from "../../../assets/images/info.svg";
const MIN_ALLOWABLE_PLOT_SIZE = 1;

const BasicInfoComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form } = useCreateProtocolForm();
  const [searchParams] = useSearchParams();

  const protocolId = searchParams.get("protocol_id");
  const { Option } = Select;
  const { TextArea } = Input;

  const [existingData, setExistingData] = useState<any>(null);
  const [countries, setCountries] = useState<protocolCountries[]>([]);
  const [countryCodes, setCountryCodes] = useState<string | null>(null);
  const [cropContry, setCropCountry] = useState<any[]>([]);
  const [cropLoading, setCropLoading] = useState<boolean>(false);

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        protocolName: existingData?.name,
        protocolDescription: existingData?.description,
        protocolCountry: existingData?.crop_details?.country,
        protocolCountries: existingData?.crop_details?.countries,
        protocolCropId: existingData?.crop_details?.crop_id,
        protocolCrop: existingData?.crop,
        plotMinLength: existingData?.plot_minimum_size?.length,
        plotMinWidth: existingData?.plot_minimum_size?.width,
        plotRecommendedLength: existingData?.plot_recommended_size?.length,
        plotRecommendedWidth: existingData?.plot_recommended_size?.width,
      });
      fetchData(existingData?.crop_details?.country).catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [existingData]);

  const handleCountries = (value: string): void => {
    if (value !== "") {
      const countryName = countries?.find(
        (item) => item.iso3166_alpha2_code === value
      )?.country_name;
      form.setFieldsValue({
        protocolCountries: [countryName],
        protocolCountry: value,
      });
      setCountryCodes(value);
    } else {
      form.setFieldsValue({
        protocolCountry: undefined,
      });
      setCountryCodes("");
      setCropCountry([]);
    }
  };

  const ApiCall = async (country: string): Promise<any> => {
    try {
      setCropLoading(true);
      const response = await PlatFormAPIAxiosInstance.get(
        `crops?showDeleted=false&location=${country ?? ""}`
      );
      return response?.data?.content;
    } catch (error) {
      console.error("Error While Fetching Data", error);
    }
  };

  const fetchData = async (countryCodes: string): Promise<void> => {
    const CropData = [countryCodes].map(
      async (item: any) => await ApiCall(item)
    );
    try {
      const res = await Promise.all(CropData);
      setCropLoading(false);
      setCropCountry(res.flat());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (countryCodes) {
      fetchData(countryCodes).catch((error) => {
        console.error("Error fetching data:", error);
      });
      form.setFieldsValue({
        protocolCrop: "",
      });
      setCropCountry([]);
    }
  }, [countryCodes]);

  const cropOptions = formatCropsData(cropContry);

  const sortedCropOptions = [...cropOptions].sort((a: any, b: any) =>
    a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
  );

  const getCountries = async (): Promise<any> => {
    try {
      const response = await PlatFormAPIAxiosInstance.get(
        "/utils/geo/regulatory-zones"
      );
      setCountries(response.data?.content);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountries().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, []);

  function validateLength(
    rule: any,
    value: string,
    callback: any,
    maxWords: number
  ): void {
    if (!value) {
      callback();
      return;
    }
    const wordCount = value.length;
    if (wordCount >= maxWords) {
      maxWords === 100
        ? callback(
            formatTranslation(t("createProtocol.basicInfo.protocolNameMessage"))
          )
        : callback(
            formatTranslation(
              t("createProtocol.basicInfo.protocolDescriptionMessage")
            )
          );
    } else {
      callback();
    }
  }

  return (
    <div className="basicInfo-component">
      <div className="basicInfo-container">
        <h2>{formatTranslation(t("createProtocol.basicInfo.title"))}</h2>
        <p>{formatTranslation(t("createProtocol.basicInfo.description"))}</p>

        <div className="basicInfo-form-section">
          <Form.Item
            key="name"
            className="basic-info-form-item"
            name="protocolName"
            label={formatTranslation(
              t("createProtocol.basicInfo.protocolName")
            )}
            rules={[
              { required: true },
              {
                validator: (rule, value, callback) => {
                  validateLength(rule, value, callback, 100);
                },
              },
            ]}
          >
            <Input
              placeholder={formatTranslation(
                t("createProtocol.basicInfo.protocolNamePlaceholder")
              )}
              maxLength={100}
              onChange={(e) => {
                const wordCount = e.target.value.length;
                if (wordCount <= 100) {
                  form.setFieldsValue({ protocolName: e.target.value });
                }
              }}
            ></Input>
          </Form.Item>

          <Form.Item
            key="protocolDescription"
            name="protocolDescription"
            className="basic-info-form-item"
            label={formatTranslation(
              t("createProtocol.basicInfo.protocolDescription")
            )}
            rules={[
              {
                validator: (rule, value, callback) => {
                  validateLength(rule, value, callback, 500);
                },
              },
            ]}
          >
            <TextArea
              placeholder={formatTranslation(
                t("createProtocol.basicInfo.protocolDesPlaceholder")
              )}
              maxLength={500}
              rows={3}
              onChange={(e) => {
                const wordCount = e.target.value.length;
                if (wordCount <= 500) {
                  form.setFieldsValue({ protocolDescription: e.target.value });
                }
              }}
            ></TextArea>
          </Form.Item>

          <Flex gap={24}>
            <ConfigProvider
              renderEmpty={() => <DropdownEmptyState isloading={cropLoading} />}
            >
              <Form.Item
                key="protocolCountry"
                name="protocolCountry"
                className="basic-info-form-item"
                label="Country"
                style={{ width: "100%" }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  size="large"
                  optionFilterProp="label"
                  placeholder={formatTranslation(
                    t("createProtocol.basicInfo.protocolCountryPlaceholder")
                  )}
                  onChange={handleCountries}
                >
                  {countries.map((country: protocolCountries) => (
                    <Option
                      key={country?.id}
                      value={country?.iso3166_alpha2_code}
                      label={country?.country_name}
                    >
                      <span className="country-code">
                        {country?.iso3166_alpha2_code}
                      </span>{" "}
                      {country?.country_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </ConfigProvider>

            <ConfigProvider renderEmpty={() => <DropdownEmptyState />}>
              <Form.Item
                key="protocolCrop"
                name="protocolCrop"
                className="basic-info-form-item"
                label={formatTranslation(
                  t("createProtocol.basicInfo.protocolCrop")
                )}
                style={{ width: "100%" }}
                rules={[{ required: true }]}
              >
                <Select
                  loading={cropLoading}
                  showSearch
                  size="large"
                  allowClear
                  optionFilterProp="label"
                  placeholder={formatTranslation(
                    t("createProtocol.basicInfo.protocolCropPlaceholder")
                  )}
                  disabled={countryCodes === ""}
                  onChange={(value) => {
                    const cropName = sortedCropOptions.find(
                      (item) => item?.value === value
                    );
                    form.setFieldsValue({
                      protocolCropId: value,
                      protocolCrop: cropName?.label,
                    });
                  }}
                  options={sortedCropOptions}
                  optionRender={(option) => (
                    <Space>{option?.data?.label}</Space>
                  )}
                />
              </Form.Item>
            </ConfigProvider>
          </Flex>

          <Flex gap={24} justify="space-between">
            <div className="basicinfo-plot">
              <div className="form-plot-size">
                {formatTranslation(
                  t("createProtocol.basicInfo.plotMinimumSize")
                )}{" "}
                <span>
                  <Tooltip
                    placement="right"
                    title={formatTranslation(
                      t("createProtocol.basicInfo.plotMinimumSizeTooltip")
                    )}
                  >
                    <img
                      className="plotsize-info-icon"
                      src={infoIcon}
                      alt="info icon"
                    />
                  </Tooltip>
                </span>
              </div>
              <Flex gap={12} justify="space-between">
                <Form.Item
                  key="plotMinWidth"
                  name="plotMinWidth"
                  className="basicInfo-input-num-form-item"
                  label={formatTranslation(t("createProtocol.basicInfo.width"))}
                  rules={[
                    { required: true },
                    {
                      type: "number",
                      max: form.getFieldValue("plotRecommendedWidth"),
                      message: formatTranslation(
                        t("createProtocol.basicInfo.plotMinimumSizeMessage")
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    controls={true}
                    size="large"
                    min={1}
                    max={100000}
                    className="basicInfo-input-num"
                    onChange={(value) => {
                      form.setFieldsValue({ plotMinWidth: value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  key="plotMinLength"
                  name="plotMinLength"
                  className="basicInfo-input-num-form-item"
                  label={formatTranslation(
                    t("createProtocol.basicInfo.length")
                  )}
                  rules={[
                    { required: true },
                    {
                      type: "number",
                      max: form.getFieldValue("plotRecommendedLength", true),
                      message: formatTranslation(
                        t("createProtocol.basicInfo.plotMinimumSizeMessage")
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    min={1}
                    max={100000}
                    className="basicInfo-input-num"
                    onChange={(value) => {
                      form.setFieldsValue({ plotMinLength: value });
                    }}
                  />
                </Form.Item>
              </Flex>
            </div>

            <div className="basicinfo-plot">
              <div className="form-plot-size">
                {formatTranslation(
                  t("createProtocol.basicInfo.plotRecommendedSize")
                )}{" "}
                <span>
                  <Tooltip
                    placement="right"
                    title={formatTranslation(
                      t("createProtocol.basicInfo.plotRecommendedSizeTooltip")
                    )}
                  >
                    <img
                      className="plotsize-info-icon"
                      src={infoIcon}
                      alt="info icon"
                    />
                  </Tooltip>
                </span>
              </div>
              <Flex gap={12} justify="space-between">
                <Form.Item
                  key="plotRecommendedWidth"
                  name="plotRecommendedWidth"
                  className="basicInfo-input-num-form-item"
                  label={formatTranslation(t("createProtocol.basicInfo.width"))}
                  rules={[
                    { required: true },
                    {
                      type: "number",
                      min: Math.max(
                        form.getFieldValue("plotMinWidth", true),
                        MIN_ALLOWABLE_PLOT_SIZE
                      ),
                      message: formatTranslation(
                        t("createProtocol.basicInfo.plotRecommendedSizeMessage")
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="basicInfo-input-num"
                    onChange={(value) => {
                      form.setFieldsValue({ plotRecommendedWidth: value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  key="plotRecommendedLength"
                  name="plotRecommendedLength"
                  className="basicInfo-input-num-form-item"
                  label={formatTranslation(
                    t("createProtocol.basicInfo.length")
                  )}
                  rules={[
                    { required: true },
                    {
                      type: "number",
                      min: Math.max(
                        form.getFieldValue("plotMinLength", true),
                        MIN_ALLOWABLE_PLOT_SIZE
                      ),
                      message: formatTranslation(
                        t("createProtocol.basicInfo.plotRecommendedSizeMessage")
                      ),
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="basicInfo-input-num"
                    onChange={(value) => {
                      form.setFieldsValue({ plotRecommendedLength: value });
                    }}
                  />
                </Form.Item>
              </Flex>
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoComponent;
