import React from "react";
import { ReactComponent as EmptyStateIcon } from "../../../assets/images/IllustrationSmallEmpty.svg";
import "./dropdownEmptyState.less";
import { Loader } from "../../components/Loader";

interface DropdownEmptyStateProps {
  isloading?: boolean;
}

export const DropdownEmptyState = ({
  isloading = false,
}: DropdownEmptyStateProps): JSX.Element => {
  return (
    <div className="dropdown-empty-state">
      {isloading ? (
        <Loader data-testid="loader" mask={false} />
      ) : (
        <>
          <p>No Results</p>
          <EmptyStateIcon className="icon-empty-state" />
        </>
      )}
    </div>
  );
};
