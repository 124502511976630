import { get } from "lodash";
import { type StandardBiological, type StandardFertiliser } from "../types";

export const populateRatesDosage = (
  trialPlotData: any,
  activeKey: string[],
  existingRates: any,
  unit?: string,
  standardSeedRate?: number,
  standardProduct?: string,
  standardfertilisers?: StandardFertiliser[],
  standardBiologicalApplicationSettings?: StandardBiological[]
): any => {
  const ratesAndDosages: any = [];
  const existingRatesDosage = existingRates?.rates_and_dosages || [];

  const formattedData = trialPlotData?.map((item: any) => {
    const biologicals = item?.properties?.biologicals || null;
    if (biologicals) {
      const { treatments } = biologicals;
      const transformedTreatments = treatments?.[0]?.map(
        (_: any, colIndex: number) =>
          treatments?.map((row: any) => row[colIndex])
      );
      return {
        ...item,
        properties: {
          ...item?.properties,
          biologicals: {
            ...biologicals,
            treatments: transformedTreatments || [],
          },
        },
      };
    }
    return item;
  });

  formattedData?.forEach((trialPlot: any, index: number) => {
    const ratesForPlotsAndReplicants: any = [];
    const { id, rows, columns } = trialPlot?.plot;
    const dataAlreadyFilledSeeds = get(
      trialPlot,
      "properties.seeds.rates_and_dosages",
      []
    );

    const dataAlreadyFilledBiologicals = get(
      trialPlot,
      "properties.biologicals.treatments",
      []
    ).map((obj: any) => {
      return {
        bioTreatement: obj,
      };
    });

    const dataAlreadyFilledNutrients = get(
      trialPlot,
      "properties.fertilisers.rates_and_dosages",
      []
    ).map((obj: any) => {
      return {
        fertiliser_rate: obj?.rate,
        fertiliser_amount: obj?.amount,
      };
    });

    const existtingFilledDaata =
      [
        dataAlreadyFilledSeeds,
        dataAlreadyFilledBiologicals,
        dataAlreadyFilledNutrients,
      ]?.find((arr) => arr.length > 0) || [];

    const mergedData = existtingFilledDaata?.map(
      (seedData: any, index: number) => {
        const biologicalData = dataAlreadyFilledBiologicals[index];
        const nutrientData = dataAlreadyFilledNutrients[index];
        return { ...seedData, ...biologicalData, ...nutrientData };
      }
    );

    const existingRD = existingRates?.length
      ? existingRates[index]
      : mergedData;

    if (existingRD?.length === rows * columns) {
      const allPlotIds: string[] = [];
      // Set the plot_id as it is not storing in DB
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          allPlotIds.push(plotId);
        }
      }
      ratesAndDosages?.push(
        existingRD?.map((e: any, i: number) => {
          return { ...e, plot_id: allPlotIds[i], collapseValue: activeKey };
        })
      );
    } else {
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {
          const plotId = `${id as number}-${row}-${col}`;
          const existingData = existingRatesDosage[index]?.find(
            (item: any) => item.plot_id === plotId
          );
          ratesForPlotsAndReplicants.push({
            plot_id: plotId,
            rate: 0,
            variety: "",
            collapseValue: activeKey,
            fertiliser_rate: 0,
            fertiliser_amount: 0,
          });
        }
      }
      ratesAndDosages.push(ratesForPlotsAndReplicants);
    }
  });

  return {
    rates_and_dosages: ratesAndDosages,
    type: "seeds",
    unit_of_rate: unit ?? "seeds/ha", // TODO: Check if this is correct
    standard_seed_rate: standardSeedRate,
    standard_product: standardProduct,
    standard_fertilisers: standardfertilisers,
    standard_biological_application_settings:
      standardBiologicalApplicationSettings,
  };
};

export default populateRatesDosage;
