import { useState, useEffect, useCallback } from "react";
import { Select, Form, Tooltip, ConfigProvider, Spin } from "antd";
import { Option } from "antd/es/mentions";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
import infoIcon from "../../../assets/images/info.svg";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./style.less";
import AxiosInstance from "../../utils/axios";
import PlatFormAPIAxiosInstance from "../../utils/platFormAxios";
import { DropdownEmptyState } from "../DropdownEmptyState";
const StandardSeedRate = () => {
  const { t } = useTranslation();
  const { ratesDosage, setRatesDosage, trialInfo, setIsStandardDataValid } =
    useNewTrialState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const getProtocolData = useCallback(async () => {
    try {
      const protocolData = await AxiosInstance.get(
        `/protocol/${trialInfo.protocol_id}`
      );
      const payload = {
        localized_crop_id: [protocolData.data.crop_details.crop_id],
        show_cononical: true,
      };
      const cropData = await PlatFormAPIAxiosInstance.post(
        "/catalog/seeds/query?page=1&size=100",
        payload
      );
      setProducts(cropData.data.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [trialInfo.protocol_id]);
  useEffect(() => {
    if (trialInfo.protocol_id) {
      setLoading(true); // Show loader when fetching data
      getProtocolData()
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Hide loader after data is fetched
        });
    }
  }, [trialInfo.protocol_id]);
  useEffect(() => {
    if (
      trialInfo.standard_field_properties &&
      trialInfo.standard_field_properties !== null
    )
      setRatesDosage({
        ...ratesDosage,
        standard_seed_rate:
          trialInfo.standard_field_properties.standard_seed_rate,
        standard_product: trialInfo.standard_field_properties.standard_product,
        standard_fertilisers:
          trialInfo.standard_field_properties.standard_fertilisers ?? [],
        standard_biological_application_settings:
          trialInfo?.standard_field_properties
            ?.standard_biological_application_settings ?? [],
      });
  }, [trialInfo.standard_field_properties]);
  useEffect(() => {
    if (
      ratesDosage.standard_seed_rate == null ||
      ratesDosage.standard_seed_rate === 0 ||
      !ratesDosage.standard_product
    ) {
      setIsStandardDataValid(false);
    } else {
      setIsStandardDataValid(true);
    }
  }, [ratesDosage.standard_seed_rate, ratesDosage.standard_product]);
  const handleStandardSeedRateChange = (seedRate) => {
    const updatedRatesAndDosage = {
      ...ratesDosage,
      standard_seed_rate: seedRate,
    };
    setRatesDosage(updatedRatesAndDosage);
  };
  const handleStandardProductChange = (product) => {
    const updatedRatesAndDosage = {
      ...ratesDosage,
      standard_product: product,
    };
    setRatesDosage(updatedRatesAndDosage);
  };
  const shouldDisplayProductError = () => {
    if (
      ratesDosage.standard_seed_rate != null &&
      !ratesDosage.standard_product
    ) {
      return true;
    }
    return false;
  };
  const shouldDisplayRateError = () => {
    if (
      ratesDosage.standard_product &&
      (!ratesDosage.standard_seed_rate ||
        ratesDosage.standard_seed_rate == null)
    ) {
      return true;
    }
    return false;
  };
  const dropdownRender = (menu) => (
    <div>
      {loading ? ( // Show loader if loading
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Spin size="small" />
        </div>
      ) : (
        menu // Render the dropdown menu if not loading
      )}
    </div>
  );
  return (
    <div className="standard-seedrate-main">
      <div className="seedrate-header">
        <p className="seedrate-heading">
          {formatTranslation(
            t("trials.plotDesign.trialPlots.table.standardSeedRate")
          )}
        </p>
        <Tooltip
          placement="right"
          title={formatTranslation(
            t("trials.plotDesign.standardSeedRate.tooltip")
          )}
        >
          <img src={infoIcon} alt="info-icon" className="seedrate-info-icon" />
        </Tooltip>
      </div>
      <Form className="seedrate-selections">
        <ConfigProvider renderEmpty={DropdownEmptyState}>
          <Form.Item label="Product" className="select-product">
            <Select
              showSearch
              placeholder={formatTranslation(
                t("trials.plotDesign.trialPlots.select")
              )}
              value={ratesDosage.standard_product ?? undefined}
              onChange={(product) => {
                handleStandardProductChange(product);
              }}
              className="selected-product"
              dropdownRender={dropdownRender} // Custom dropdown render
              loading={loading} // Enable loading state for Select
            >
              {products.length > 0 &&
                products
                  .slice()
                  .sort((a, b) =>
                    a.commercial_name.localeCompare(b.commercial_name)
                  )
                  .map((product, index) => {
                    return (
                      <Option key={index} value={product.commercial_name}>
                        {product.variety}
                      </Option>
                    );
                  })}
            </Select>
            {shouldDisplayProductError() && (
              <span className="error-message">
                {formatTranslation(
                  t("trials.plotDesign.trialPlots.errorText.variety")
                )}
              </span>
            )}
            {shouldDisplayRateError() && (
              <span className="error-message">
                {formatTranslation(
                  t("trials.plotDesign.trialPlots.errorText.rate")
                )}
              </span>
            )}
          </Form.Item>
        </ConfigProvider>
        <Form.Item
          label={`Seed Rate (${ratesDosage.unit_of_rate})`}
          className="input-seedrate"
        >
          <InputNumber
            type="number"
            min={0}
            value={ratesDosage.standard_seed_rate ?? 0}
            onChange={(seedRate) => {
              handleStandardSeedRateChange(seedRate);
            }}
            className="input-number"
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export default StandardSeedRate;
