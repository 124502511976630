import { useEffect, useState, type FC } from "react";
import { Form, Input, Select, ConfigProvider } from "antd";
import useFormHandling from "../hooks/useFormHandling";
import { FieldCard } from "../../../shared/layout/FieldCard";
import { type FieldDetailsType } from "../types";
import { DropdownEmptyState } from "../../../shared/layout/DropdownEmptyState";
import useAxios from "../../../shared/custom-hooks/useAxios";
import { type ProtocolInterface } from "./type";
import { useAppState } from "../../../lib/appContext/AppContext";
import { useNewTrialState } from "../hooks/NewTrialStateProvider";
import { route } from "../../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import PlatFormAPIAxiosInstance from "../../../shared/utils/platFormAxios";

const TrailInfoComponent: FC = () => {
  const { t } = useTranslation();
  const {
    form,
    RangePicker,
    setdefaultAssigneeId,
    handleFieldSelection,
    fieldData,
  } = useFormHandling();
  const { stateValue, setStateValue } = useAppState();
  const { response: protocolsList } = useAxios(
    `/${route.PROTOCOLS.path}/dropdown?country=${
      stateValue?.orgValue?.country ?? ""
    }`
  );
  const orgId = stateValue.orgValue?.id ?? null;
  const { trialInfo, setTrialInfo } = useNewTrialState();
  const [assignee, setAssignee] = useState<any>([]);
  const propertyId = stateValue?.farmValue?.id ?? fieldData?.[0]?.property_id;
  const getWorkers = async (): Promise<any> => {
    const response = orgId
      ? await PlatFormAPIAxiosInstance.post(
          `/orgs/${orgId}/properties/ids/workers`,
          {
            ids: [propertyId ?? ""],
          }
        )
      : { data: { content: [] } };
    return response.data;
  };

  useEffect(() => {
    if (propertyId) {
      getWorkers()
        .then((data) => {
          data?.content?.sort((a: any, b: any) => {
            const nameA = a?.name ? a?.name?.toLowerCase?.() : "";
            const nameB = b?.name ? b?.name?.toLowerCase?.() : "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          setAssignee(data ?? []);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [orgId, propertyId]);

  const assigneList = Array.isArray(assignee?.content)
    ? assignee?.content.map((data: any) => {
        return { value: data?.id, label: data?.name };
      })
    : [];

  // Sort protocolsList by name
  const sortedProtocolsList = Array.isArray(protocolsList)
    ? protocolsList.sort((a, b) => a?.name.localeCompare(b.name))
    : [];

  useEffect(() => {
    if (Array.isArray(assigneList) && assigneList?.length) {
      setStateValue({
        ...stateValue,
        assigneeValue: assigneList,
      });
    }
  }, [assignee]);

  useEffect(() => {
    setTrialInfo({
      ...trialInfo,
      field_ids: trialInfo?.field_ids,
    });
    form.setFieldValue("field_ids", trialInfo?.field_ids[0]);
  }, [trialInfo?.field_ids]);

  return (
    <>
      <div className="rates-main">
        <h3 className="title">
          {formatTranslation(t("newTrial.trialInfo.title"))}
        </h3>
      </div>
      <Form.Item
        key="name"
        name="name"
        label={formatTranslation(t("trials.tableTitle1"))}
        rules={[{ required: true }]}
      >
        <Input
          data-testid="trail-name"
          onChange={(e) => {
            form.setFieldsValue({ trialName: e.target.value });
          }}
          placeholder={formatTranslation(t("trials.tableTitle1"))}
        />
      </Form.Item>
      <ConfigProvider
        renderEmpty={() => <DropdownEmptyState isloading={false} />}
      >
        <Form.Item
          key={"protocol_id"}
          name={"protocol_id"}
          rules={[{ required: true }]}
          label={formatTranslation(t("newTrial.trialInfo.protocolSelection"))}
        >
          <Select
            showSearch
            data-testid="protocol-selection"
            placeholder={formatTranslation(
              t("newTrial.trialInfo.trialPlaceholder")
            )}
            onChange={(value) => {
              const selectedProtocol = sortedProtocolsList.find(
                (protocol: ProtocolInterface) => protocol?.id === value
              );
              if (selectedProtocol) {
                form.setFieldsValue({
                  protocolSelection: selectedProtocol?.name,
                });
              }
            }}
            allowClear
            value={form.getFieldValue("protocolSelection")}
            filterOption={(input, option) => {
              const label = option?.label?.toString() ?? "";
              const inputLower = input.toString().toLowerCase();
              const labelLower = label.toLowerCase();
              const labelUpper = label.toUpperCase();
              return (
                labelLower.includes(inputLower) ||
                labelUpper.includes(inputLower)
              );
            }}
            options={sortedProtocolsList?.map(
              (protocol: ProtocolInterface) => ({
                value: protocol?.id,
                label: protocol?.name,
                "data-testid": protocol?.id,
              })
            )}
          ></Select>
        </Form.Item>
      </ConfigProvider>
      <div className="grid-row">
        <Form.Item
          label={formatTranslation(t("newTrial.trialInfo.trialDate"))}
          key="rangePicker"
          name="rangePicker"
          rules={[{ required: true }]}
        >
          <RangePicker
            style={{ width: "100%" }}
            data-testid="range-picker"
            onChange={(date: any) => {
              form.setFieldsValue({ rangePicker: date });
            }}
          />
        </Form.Item>

        <ConfigProvider renderEmpty={() => <DropdownEmptyState />}>
          <Form.Item
            key={"assignee_id"}
            name={"assignee_id"}
            label={formatTranslation(t("trials.tableTitle3"))}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              data-testid="assignee-input"
              placeholder={formatTranslation(
                t("newTrial.trialInfo.trialPlaceholder")
              )}
              allowClear
              defaultValue={setdefaultAssigneeId}
              onChange={(value) => {
                form.setFieldsValue({ assignee_id: value });
              }}
              filterOption={(input, option) => {
                const label = option?.label?.toString() ?? "";
                const inputLower = input.toString().toLowerCase();
                const labelLower = label.toLowerCase();
                const labelUpper = label.toUpperCase();
                return (
                  labelLower.includes(inputLower) ||
                  labelUpper.includes(inputLower)
                );
              }}
              options={assigneList}
            ></Select>
          </Form.Item>
        </ConfigProvider>
      </div>
      <Form.Item
        label={formatTranslation(t("newTrial.trialInfo.fields"))}
        key="field_ids"
        name="field_ids"
        rules={[{ required: true }]}
      >
        <div className="field-map">
          <div className="card-main">
            {stateValue.farmValue ? (
              fieldData
                ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                .map((field: FieldDetailsType, index: number) => (
                  <FieldCard
                    key={`${field.fieldName}-${index}`}
                    fieldName={field?.name}
                    image={field?.image}
                    id={field?.id}
                    onChange={(e: any) => {
                      handleFieldSelection(e.target.value);
                    }}
                    geojson={field?.geometry}
                  />
                ))
            ) : (
              <p style={{ marginLeft: 10 }}>
                {formatTranslation(t("newTrial.trialInfo.noInfo"))}
              </p>
            )}
          </div>
        </div>
      </Form.Item>
    </>
  );
};

export default TrailInfoComponent;
