import { type ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import { Tabs } from "antd";
import { protocolProductTabs } from "../constants";
import { getDefaultActiveTabs } from "../utils";
import { useCreateProtocolForm } from "..";

const ProductsComponent = (): ReactElement => {
  const { formValidationData } = useCreateProtocolForm();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const onChange = (key: string): void => {};

  return (
    <div className="products-component">
      <Tabs
        defaultActiveKey={
          type ?? getDefaultActiveTabs(formValidationData?.protocolType || [])
        }
        items={protocolProductTabs(formValidationData?.protocolType || [])}
        onChange={onChange}
        // Force render all tabs to register form fields
        destroyInactiveTabPane={false} // Prevents unmounting inactive tabs
      />
    </div>
  );
};

export default ProductsComponent;
