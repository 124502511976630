import { Step } from "./constants";
import { type NewTrialStateInterface, type StepItemType } from "./types";

export const getItems = (): Array<{ key: string; title: string }> => {
  return Step.map((item: StepItemType) => ({
    key: item.title,
    title: item.title,
  }));
};

export const getCancelButtonLabel = (current: number): string => {
  if (current === 0) return "Cancel";
  return "Back";
};

export const getNextButtonLabel = (
  current: number,
  t: any,
  formatTranslation: any
): string => {
  if (current === Step.length - 1)
    return formatTranslation(t("trials.saveAndExportWorkOrder"));
  return Step[current + 1].title;
};

export const getNext = (
  current: number,
  setCurrent: React.Dispatch<React.SetStateAction<number>>
): void => {
  setCurrent(current + 1);
};

export const getPrev = (
  current: number,
  setCurrent: React.Dispatch<React.SetStateAction<number>>
): void => {
  setCurrent(current - 1);
};

export const downloadWorkOrder = (blob: Blob): void => {
  const url = window.URL.createObjectURL(blob);
  const documentLink = document.createElement("a");
  documentLink.href = url;
  documentLink.download = "downloadWorkOrder";
  document.body.appendChild(documentLink);
  documentLink.click();
  documentLink.remove();
  window.URL.revokeObjectURL(url);
};

export const allowFieldInputChange = (
  trialInfo: any,
  newTrialState: NewTrialStateInterface
): boolean => {
  if (
    trialInfo?.ab_line?.length > 0 ||
    trialInfo?.buffer_zone?.size ||
    trialInfo?.trial_plots?.length > 0 ||
    newTrialState?.ab_line?.length > 0 ||
    newTrialState?.buffer_zone?.length > 0 ||
    newTrialState?.trial_plots?.length > 0
  ) {
    return false;
  } else {
    return true;
  }
};
