import { Button, Tooltip } from "antd";
import type { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import type { UnarchiveIconProps } from "../types";
import undoIcon from "../../../assets/images/undo.svg";

export const UnarchiveButton: FC<UnarchiveIconProps> = ({
  onClick,
  children,
  loading = false,
}) => {
  const { t } = useTranslation();
  const handleClick = (e: MouseEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    void onClick();
  };
  return (
    <Tooltip
      title={formatTranslation(t("protocols.unarchive"))}
      placement="left"
    >
      <Button
        type="text"
        onClick={handleClick}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        loading={loading}
        disabled={loading}
      >
        <img src={undoIcon} alt="undo icon" data-testid="archive-icon" />
        {children}
      </Button>
    </Tooltip>
  );
};
