import { useState, type FC } from "react";
import { Button, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import archiveIcon from "../../../assets/images/archive-hover.svg";
import archiveDefaultIcon from "../../../assets/images/archive.svg";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./archiveButton.less";
interface ArchiveIconProps {
  onClick: () => Promise<void>;
  children: React.ReactNode;
  from: string;
  status?: string; // Add status prop
}
export const ArchiveButton: FC<ArchiveIconProps> = ({
  onClick,
  children,
  from,
  status,
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isDraft = status?.toLowerCase() === "draft";
  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };
  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };
  const handleConfirm = async (): Promise<void> => {
    setLoading(true);
    const startTime = Date.now();
    try {
      await onClick();

      const elapsed = Date.now() - startTime;
      if (elapsed < 1000) {
        await new Promise((resolve) => setTimeout(resolve, 1000 - elapsed));
      }
      handleCloseModal();
    } catch (error) {
      console.error("Archive failed:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Tooltip
        placement="left"
        title={formatTranslation(t("protocols.archiveButtonTooltipText"))}
        data-testid="archive-tooltip"
      >
        <Button
          type="text"
          className="archive-button"
          onClick={handleOpenModal}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <img
            src={isHovered ? archiveIcon : archiveDefaultIcon}
            alt="archive icon"
            data-testid="archive-icon"
          />
          {children}
        </Button>
      </Tooltip>
      <Modal
        title={null} // Remove default title
        open={isModalOpen}
        onOk={() => {
          void handleConfirm();
        }}
        onCancel={handleCloseModal}
        okText={formatTranslation(t("protocols.archive"))}
        cancelText={formatTranslation(t("modal.cancel"))}
        okButtonProps={{
          danger: true,
          type: "primary",
          loading,
        }}
        closable={false}
        centered
      >
        <div style={{ display: "flex", gap: 16, alignItems: "flex-start" }}>
          {/* Icon Container */}
          <div style={{ marginTop: 4 }}>
            {" "}
            {/* Adjust vertical alignment */}
            <ExclamationCircleOutlined
              style={{
                color: "#CF3537",
                fontSize: 16,
              }}
            />
          </div>
          {/* Text Content */}
          <div style={{ flex: 1 }}>
            {/* Title */}
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 8,
                lineHeight: 1.4,
              }}
              className="archive-modal-title"
            >
              {formatTranslation(
                t(
                  isDraft
                    ? "protocols.archiveDraftConfirmTitle"
                    : "protocols.archiveConfirmTitle"
                )
              )}
            </div>
            {/* Description */}
            <div style={{ color: "rgba(0, 0, 0, 0.65)", lineHeight: 1.5 }}>
              <p
                style={{ marginBottom: 8 }}
                className="archive-modal-description"
              >
                {formatTranslation(
                  t(
                    isDraft
                      ? "protocols.archiveDraftConfirmDescription"
                      : "protocols.archiveConfirmDescription"
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
