import AxiosInstance from "../../shared/utils/axios";
// Fetch protocols based on archived status
export const fetchProtocols = async (orgId: string): Promise<any[]> => {
  const response = await AxiosInstance.get(
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `/protocols?org_id=${orgId}&limit=200`
  );

  return response.data;
};
// Archive a protocol
export const archiveProtocol = async (protocolId: string): Promise<void> => {
  await AxiosInstance.patch(`/protocol/${protocolId}`, { archived: true });
};
// Unarchive a protocol
export const unarchiveProtocol = async (protocolId: string): Promise<void> => {
  await AxiosInstance.patch(`/protocol/${protocolId}`, { archived: false });
};
// Delete a protocol
export const deleteProtocol = async (protocolId: string): Promise<void> => {
  await AxiosInstance.delete(`/protocol/${protocolId}`);
};
