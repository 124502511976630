import { type FC } from "react";
import PlotsComponent from "../../../../shared/layout/Plots";
import StandardBiologicalRate from "../../../../shared/layout/BiologicalRate";

const CropProtectionComponent: FC = () => {
  return (
    <div className="rates-main">
      <StandardBiologicalRate />
      <PlotsComponent from={"biologicals"} />
    </div>
  );
};

export default CropProtectionComponent;
