import React, { useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import { Loader } from "../../shared/components/Loader";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import usePagination from "../../shared/custom-hooks/usePagination";
import { UnarchiveButton } from "../../shared/layout/UnarchiveButton";

interface ProtocolTableProps {
  dataSource: any[];
  columns: any[];
  isLoading: boolean;
  onRowClick: (id: string) => void;
  onArchive: (id: string) => Promise<void>;
  onRefresh: () => void;
  activeTab: string;
  onTabChange: (key: string) => void;
  onUnarchive: (id: string) => Promise<void>;
  unarchivingIds: string[];
}
const ProtocolTable: React.FC<ProtocolTableProps> = ({
  dataSource,
  columns,
  isLoading,
  onRowClick,
  onArchive,
  onRefresh,
  onUnarchive,
  activeTab,
  onTabChange,
  ...props
}) => {
  const { t } = useTranslation();
  // const [activeTab, setActiveTab] = useState<string>("active");
  const [activePagination, setActivePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [archivedPagination, setArchivedPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Update pagination totals when dataSource changes
  useEffect(() => {
    if (activeTab === "active") {
      setActivePagination((prev) => ({
        ...prev,
        total: dataSource.filter((protocol) => !protocol.archived).length,
      }));
    } else {
      setArchivedPagination((prev) => ({
        ...prev,
        total: dataSource.filter((protocol) => protocol.archived).length,
      }));
    }
  }, [dataSource, activeTab]);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTableChange = (pagination: any) => {
    if (activeTab === "active") {
      setActivePagination(pagination);
    } else {
      setArchivedPagination(pagination);
    }
  };
  const currentPagination =
    activeTab === "active" ? activePagination : archivedPagination;
  const handleArchive = async (id: string): Promise<void> => {
    await onArchive(id);
    onRefresh();
  };
  const filteredData = dataSource.filter((protocol) =>
    activeTab === "active" ? !protocol.archived : protocol.archived
  );
  const modifiedColumns = columns.map((col) => {
    if (col.key === "actions") {
      return {
        ...col,
        render: (text: any, record: any) => {
          if (activeTab === "archived") {
            return (
              <UnarchiveButton
                loading={props.unarchivingIds.includes(record.id)}
                onClick={async () => {
                  await onUnarchive(record.id);
                  onRefresh();
                }}
              >
                {formatTranslation(t("protocols.unarchive"))}
              </UnarchiveButton>
            );
          }
          return col.render(text, record, record.status, handleArchive);
        },
      };
    }
    return col;
  });
  const tabItems = [
    {
      key: "active",
      label: formatTranslation(t("protocols.tabs.active")),
      children: (
        <Table
          className="protocol-table"
          {...props}
          dataSource={filteredData}
          columns={modifiedColumns}
          pagination={{ ...currentPagination, position: ["bottomCenter"] }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => {
              onRowClick(record.id);
            },
          })}
        />
      ),
    },
    {
      key: "archived",
      label: formatTranslation(t("protocols.tabs.archived")),
      children: (
        <Table
          className="archive-table"
          {...props}
          dataSource={filteredData}
          columns={modifiedColumns.filter((col) => col.key !== "actions")}
          pagination={{ ...currentPagination, position: ["bottomCenter"] }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => {
              onRowClick(record.id);
            },
          })}
        />
      ),
    },
  ];
  return (
    <div className="protocol-table-container">
      {isLoading && <Loader mask message="" />}
      <Tabs activeKey={activeTab} onChange={onTabChange} items={tabItems} />
    </div>
  );
};
export default ProtocolTable;
