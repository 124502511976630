// | "Initiated"
// | "Draft"
// | "In-Progress"
// | "Published"
// | "Error";

import { createSearchParams, useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { EditButton } from "../../shared/layout/EditButton";
import { route } from "../constant";
import { DeleteButton } from "../../shared/layout/DeleteButton";
import { Button, Dropdown, Flex, Tag, type MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import { formatDate } from "../create-protocol/utils";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";
import { useAppState } from "../../lib/appContext/AppContext";
import { EllipsisOutlined } from "@ant-design/icons";
import { ArchiveButton } from "../../shared/layout/ArchiveButton";
import { UnarchiveButton } from "../../shared/layout/UnarchiveButton";

export const ProtocolsColumns = (
  handleDeleteProtocol: any,
  handleArchive: (id: string) => Promise<void>,
  handleUnarchive: (id: string) => Promise<void>,
  activeTab: string
): any => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { stateValue } = useAppState();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const checkTrailCount = (val: string): number => {
    return parseInt(val, 10);
  };

  const PROTOCOLS_COLUMNS = [
    {
      title: formatTranslation(t("createProtocol.basicInfo.protocolName")),
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: string) => <span>{text}</span>,
      width: "20%",
    },
    {
      title: formatTranslation(t("protocol.createdAt")),
      dataIndex: "created_at",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      render: (creationDate: any) => formatDate(creationDate),
    },
    {
      title: formatTranslation(t("createProtocol.summary.type")),
      key: "protocol_type",
      dataIndex: ["protocol_type"],
      render: (types: string[]) => (
        <span>
          {types.map((type: string): any => {
            let colorType = "";
            let typeName = "";
            if (type === "seed") {
              colorType = "seedType";
              typeName = formatTranslation(t("trialtypes.label.seed"));
            } else if (type === "nutrient") {
              colorType = "nutrientType";
              typeName = formatTranslation(t("trialtypes.label.fertiliser"));
            } else {
              colorType = "cropProtectionType";
              typeName = formatTranslation(
                t("trialtypes.label.cropProtection")
              );
            }
            return (
              <Tag className={colorType} key={type}>
                {typeName}
              </Tag>
            );
          })}
        </span>
      ),

      // else return types.map((type: string) => type).join(" ");
    },
    {
      title: formatTranslation(
        t("createProtocol.objectives.objectiveItemTarget")
      ),
      dataIndex: "objectives",
      render: (objectives: any) => {
        if (objectives === null) return "";
        else
          return objectives
            .map((objective: any) => objective.targets)
            .join(", ");
      },
      key: "target",
    },
    {
      title: formatTranslation(t("createProtocol.basicInfo.protocolCrop")),
      dataIndex: "crop",
      key: "crop",
      sorter: (a: any, b: any) => a.crop.localeCompare(b.crop),
    },
    {
      title: formatTranslation(t("trials")),
      dataIndex: "trial_count",
      key: "trials",
      sorter: (a: any, b: any) =>
        parseInt(a.trial_count) - parseInt(b.trial_count),
    },
    {
      title: formatTranslation(t("trials.status")),
      dataIndex: "status",
      key: "status",
      filters:
        activeTab === "active"
          ? [
              { text: "Draft", value: "draft" },
              { text: "Published", value: "published" },
            ]
          : null,
      onFilter:
        activeTab === "active"
          ? (value: string, record: any) =>
              record.status.toLowerCase() === value.toLowerCase()
          : null,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (status: string, record: any) => (
        <span
          className={`status-column ${
            record.archived
              ? "protocol-archived"
              : status === "published"
              ? "protocol-published"
              : "protocol-draft"
          }`}
        >
          {record.archived ? "archived" : status?.toUpperCase()}
        </span>
      ),
    },
    {
      title: formatTranslation(t("trials.tableTitle7")),
      dataIndex: "actions",
      render: (
        text: any,
        record: {
          name: string;
          id: string;
          trial_count: string;
          status: string;
        }
      ) => {
        if (activeTab === "archived") {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <UnarchiveButton
                onClick={async () => {
                  await handleUnarchive(record.id);
                  amplitude.logEvent("protocol", {
                    buttonName: "unarchive-protocol",
                    amplitudeUserData,
                  });
                }}
              >
                {formatTranslation(t("createProtocol.table.unarchive"))}
              </UnarchiveButton>
            </div>
          );
        }
        const items: MenuProps["items"] = [
          {
            key: "edit",
            label: (
              <div className="dropdown-item">
                <EditButton
                  from="protocols" // Set the appropriate context
                  onClick={() => {
                    const e = window.event;
                    e?.stopPropagation();
                    amplitude.logEvent("protocol", {
                      buttonName: "edit-protocol",
                      amplitudeUserData,
                    });
                    navigate({
                      pathname: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
                      search: createSearchParams({
                        protocol_id: record.id,
                      }).toString(),
                    });
                  }}
                  disabled={checkTrailCount(record.trial_count) > 0}
                >
                  <Flex align="center" gap={8}>
                    {formatTranslation(t("createProtocol.table.edit"))}
                  </Flex>
                </EditButton>
              </div>
            ),
            style: {
              padding: 0,
              backgroundColor: "transparent",
            },
          },
          {
            key: "archive",
            label: (
              <div className="dropdown-item">
                <ArchiveButton
                  from="protocols" // Set the appropriate context
                  onClick={async () => {
                    await handleArchive(record.id);
                  }}
                  status={record.status}
                >
                  <Flex align="center" gap={8}>
                    {formatTranslation(t("createProtocol.table.archive"))}
                  </Flex>
                </ArchiveButton>
              </div>
            ),
            onClick: (e) => {
              e.domEvent.stopPropagation();
            },
            style: {
              padding: 0,
              backgroundColor: "transparent",
            },
          },
          {
            key: "delete",
            label: (
              <div style={{ width: "100%" }}>
                <div className="dropdown-item">
                  <DeleteButton
                    onClick={() => handleDeleteProtocol(record.id)}
                    disabled={checkTrailCount(record.trial_count) > 0}
                    dataName={record.name}
                  >
                    <Flex
                      align="center"
                      gap={8}
                      style={{
                        opacity:
                          checkTrailCount(record.trial_count) > 0 ? 0.8 : 1,
                        cursor:
                          checkTrailCount(record.trial_count) > 0
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      {formatTranslation(t("createProtocol.table.delete"))}
                    </Flex>
                  </DeleteButton>
                </div>
              </div>
            ),
            style: {
              padding: 0,
              backgroundColor: "transparent",
            },
            disabled: checkTrailCount(record.trial_count) > 0, // Add disabled state to menu item
          },
        ];
        return (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="bottomRight"
              overlayStyle={{
                minWidth: 160,
                zIndex: 1000,
              }}
            >
              <Button
                type="text"
                icon={<EllipsisOutlined style={{ fontSize: 16 }} />}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                style={{
                  padding: 0,
                  border: "none",
                  boxShadow: "none",
                  width: 32,
                  height: 32,
                }}
              />
            </Dropdown>
          </div>
        );
      },
      fixed: true,
      width: 120,
    },
  ];
  return PROTOCOLS_COLUMNS;
};
