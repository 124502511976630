import React, { useState, useEffect } from "react";
import infoIcon from "../../../assets/images/info.svg";
import { Flex, Tooltip, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import "./style.less";

const StandardBiologicalRate = () => {
  const { t } = useTranslation();
  const {
    ratesDosage,
    setRatesDosage,
    trialInfo,
    trialProtocolData,
    setIsStandardDataValid,
  } = useNewTrialState();
  const [biologicalproducts, setBiologicalProducts] = useState(null);

  useEffect(() => {
    if (trialProtocolData?.product_details?.biologicals) {
      setBiologicalProducts(trialProtocolData?.product_details?.biologicals);
    }
  }, [trialProtocolData]);

  useEffect(() => {
    const tankVolume =
      ratesDosage?.standard_biological_application_settings?.[0]
        ?.tank_application_volume;
    if (
      tankVolume == null ||
      tankVolume === 0 ||
      !tankVolume ||
      tankVolume < biologicalproducts?.tank_mix_rate.min ||
      tankVolume > biologicalproducts?.tank_mix_rate.max
    ) {
      setIsStandardDataValid(false);
    } else {
      setIsStandardDataValid(true);
    }
  }, [ratesDosage]);

  useEffect(() => {
    if (
      trialInfo?.standard_field_properties &&
      trialInfo.standard_field_properties !== null
    ) {
      const newData = {
        ...ratesDosage,
        standard_seed_rate:
          trialInfo?.standard_field_properties?.standard_seed_rate,
        standard_product:
          trialInfo?.standard_field_properties?.standard_product,
        standard_fertilisers:
          trialInfo?.standard_field_properties?.standard_fertilisers,
        standard_biological_application_settings:
          trialInfo?.standard_field_properties
            ?.standard_biological_application_settings ?? [],
      };
      setRatesDosage(newData);
    }
  }, [
    trialInfo?.standard_field_properties,
    !ratesDosage?.standard_biological_application_settings,
  ]);

  const measureUnit =
    biologicalproducts &&
    biologicalproducts?.products[0]?.measure_unit === "LITERS"
      ? "L/ha"
      : "kg/ha";

  const handletankRateChange = (tankVolume) => {
    const applicationData = {
      product: biologicalproducts?.products[0]?.variety,
      tank_application_volume: tankVolume,
      unit: measureUnit,
    };
    setRatesDosage({
      ...ratesDosage,
      standard_biological_application_settings: [applicationData],
    });
  };

  return (
    <div className="standard-seedrate-main biological-rate-main">
      <div className="seedrate-header">
        <p className="seedrate-heading">
          {formatTranslation(
            t("trials.plotDesign.trialPlots.table.applicationSettings")
          )}
        </p>
        <Tooltip
          placement="right"
          title={formatTranslation(
            t("trials.plotDesign.StandardFertilizerRate.tooltip")
          )}
        >
          <img src={infoIcon} alt="info-icon" className="seedrate-info-icon" />
        </Tooltip>
      </div>
      <Flex justify="space-between">
        <div className="product-details application-info">
          <div className="product-info-label">Product</div>
          <div className="product-info-value">
            {biologicalproducts && biologicalproducts?.products[0]?.variety} (
            {biologicalproducts?.products[0]?.rate} {measureUnit})
          </div>
        </div>
        <div className="productRate-info application-info">
          <Form.Item
            style={{ width: "100%" }}
            label={"Tank Application Volume"}
            className="input-biologicalrate"
            rules={[
              {
                required: true,
                message: "Please input Tank Application Volume!",
              },
            ]}
          >
            <InputNumber
              min={biologicalproducts && biologicalproducts?.tank_mix_rate.min}
              max={biologicalproducts && biologicalproducts?.tank_mix_rate.max}
              value={
                ratesDosage?.standard_biological_application_settings?.length
                  ? ratesDosage?.standard_biological_application_settings[0]
                      ?.tank_application_volume
                  : 0
              }
              suffix={"L/ha"}
              onChange={(tankVolume) => {
                handletankRateChange(tankVolume);
              }}
              className="input-number"
            />
          </Form.Item>
          <div className="product-info-label">
            {biologicalproducts &&
              `Min ${biologicalproducts?.tank_mix_rate.min} L/ha - Max ${biologicalproducts?.tank_mix_rate.max} L/ha`}
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default StandardBiologicalRate;
